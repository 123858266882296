<template>
  <v-autocomplete
    v-bind:value="value"
    :items="items"
    v-on="on"
    :inputmode="inputmode"
    :error-messages="errors"
    :label="label"
    :hint="hint"
    :readonly="readonly"
    hide-details="auto"
    class="a-auto-complete"
    @input="$emit('input', $event)"
  ></v-autocomplete>
</template>
<script>
/**
 * @vue-prop {String} value - Value
 * @vue-prop {Array} items - Items
 * @vue-prop {Boolean} on - Object
 * @vue-prop {Object} validator - Validator
 * @vue-prop {String} label - Label
 * @vue-prop {String} hint - Hint
 * @vue-prop {Boolean} readonly - Read only
 * @vue-computed {String} errors
 */

export default {
  name: "a-auto-complete",

  props: {
    value: String,
    items: Array,
    on: Object,
    validator: Object,
    inputmode: String,
    label: String,
    hint: String,
    readonly: Boolean,
  },

  computed: {
    errors() {
      const errors = [];

      if (!this.validator || !this.validator.$dirty) return errors;

      typeof this.validator.required !== "undefined" &&
        !this.validator.required &&
        errors.push(this.$t("errors.fields.REQUIRED_FIELD"));

      return errors;
    },
  },
};
</script>
