<template>
  <div class="ai-search">
    <!-- Form -->
    <v-form @submit.prevent="getData">
      <v-row class="pb-6 d-flex justify-center">
        <v-col cols="2" class="pt-2">
          <a-select
            v-model="$v.formInputType.$model"
            :items="types"
            v-on:input="changeType"
          >
          </a-select>
        </v-col>
        <v-col cols="6" class="pt-2">
          <a-complete
            v-model="formInputProduct"
            :validator="$v.formInputProduct"
            :items="products"
            :label="$t('product') + $t('required')"
            v-on:input="changeInputItem"
          />
        </v-col>
        <v-col cols="1" class="pt-5 ml-2">
          <v-hover v-slot="{ hover }">
            <v-btn
              type="submit"
              color="white--text"
              :style="{
                'background-color': hover ? '#022a63' : '#00204d',
                'text-transform': 'unset !important',
              }"
              >{{ $t("run") }}</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
    </v-form>
    <!-- !Form -->
    <!-- Result -->

    <!-- Results -->
    <div class="result" v-if="show">
      <div class="result__score result-score">
        <div class="result-score__container">
          <h1 class="result-score__text">
            {{ units }} <br />
            <span class="result-score__text--size">{{ $t("unitsMonth") }}</span>
          </h1>
        </div>
        <div class="result-score__container">
          <h1 class="result-score__text">
            {{ conversionRate }} % <br />
            <span class="result-score__text--size">{{
              $t("conversionRate")
            }}</span>
          </h1>
        </div>
        <div class="result-score__container result-score__container--inverse">
          <p class="result-score__text--size">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <!-- !Results -->
      <!-- Sales -->
      <v-row class="mt-6">
        <v-col cols="12">
          <v-card
            class="d-flex align-center pa-1"
            style="border-radius: 30px"
            color="white"
            elevation="0"
          >
            <v-col cols="3">
              <v-row class="d-flex justify-center">
                <h1 class="primary--text">{{ $t("sales") }}</h1>
              </v-row>
            </v-col>
            <v-col cols="9">
              <line-chart
                v-if="lineChartData != null"
                :series="lineChartData"
                :isAISearch="true"
                :cats="cats"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <!-- !Sales -->
      <!-- Segments -->
      <v-row>
        <div class="tables container">
          <div class="tables__radio tables-radio">
            <input
              class="tables-radio__input"
              type="radio"
              name="tableSelector"
              id="table1Radio"
              value="table1"
              v-model="selectedTable"
            />
            <label class="tables-radio__label" for="table1Radio">
              {{ $t("wholeStore") }}
            </label>
          </div>
          <div class="tables__radio tables-radio">
            <input
              class="tables-radio__input"
              type="radio"
              name="tableSelector"
              id="table2Radio"
              value="table2"
              v-model="selectedTable"
            />
            <label class="tables-radio__label" for="table2Radio">
              {{ $t("sameCategory") }}
            </label>
          </div>
          <h3 class="tables__title">{{ $t("segments") }}</h3>
          <table class="tables__container" v-if="selectedTable === 'table1'">
            <thead class="tables__header">
              <tr class="tables__row">
                <th class="tables__cell-title" scope="col">
                  {{ $t("frequency") }}
                </th>
                <th class="tables__cell-title" scope="col">
                  {{ $t("mainBasket") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in wholeStoreTable" :key="item.percentage">
                <td class="tables__cell">{{ item.percentage }} %</td>
                <td class="tables__cell">{{ item.main_basket }}</td>
              </tr>
            </tbody>
          </table>
          <table class="tables__container" v-if="selectedTable === 'table2'">
            <thead class="tables__header">
              <tr class="tables__row">
                <th class="tables__cell-title" scope="col">
                  {{ $t("frequency") }}
                </th>
                <th class="tables__cell-title" scope="col">
                  {{ $t("mainBasket") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sameCategoryTable" :key="item.percentage">
                <td class="tables__cell">{{ item.percentage }} %</td>
                <td class="tables__cell">{{ item.main_basket }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-row>
      <!-- !Segments -->
      <!-- Cannibalisation -->
      <v-row class="mt-6" style="margin-left: 1px; margin-right: 1px">
        <v-card
          class="pa-10 mt-n10"
          width="100%"
          style="border-radius: 30px"
          color="white"
          elevation="0"
        >
          <v-col cols="10" style="margin-left: 1rem" class="mb-n8">
            <p
              style="
                background: #ff8c00;
                background: linear-gradient(to right, #ff8c00 0%, #0067ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 400;
                font-size: 22px;
              "
            >
              {{
                $t("whatProductCannibalisedProduct", {
                  1: formInputProduct,
                })
              }}
            </p>
          </v-col>
          <v-col cols="12">
            <div class="tables container">
              <table class="tables__container">
                <thead class="tables__header">
                  <tr class="tables__row">
                    <th class="tables__cell-title" scope="col">
                      {{ $t("sku") }}
                    </th>
                    <th class="tables__cell-title" scope="col">
                      {{ $t("description") }}
                    </th>
                    <th class="tables__cell-title" scope="col">
                      {{ $t("cannibalisation") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in cannibalisationItems" :key="item.sku">
                    <td class="tables__cell">{{ item.sku }}</td>
                    <td class="tables__cell">{{ item.description }}</td>
                    <td class="tables__cell">{{ item.cannibalisation }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-row style="margin-left: 1.8rem">
            <p
              style="
                background: #ff8c00;
                background: linear-gradient(to right, #ff8c00 0%, #0067ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 400;
                font-size: 22px;
              "
            >
              {{ $t("solution") }}
            </p>
            <p
              style="
                font-weight: 200;
                font-size: 22px;
                color: grey;
                margin-left: 4rem;
              "
            >
              {{ solution }}
            </p>
          </v-row>
        </v-card>
      </v-row>
      <!-- Cannibalisation -->
    </div>
    <!-- !Result -->
  </div>
</template>
<style lang="scss" scoped>
.col-gradient {
  border: 1px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: inset 2px 1000px 1px #fff;
  height: 120px;
  max-width: 31%;
  margin-left: 12px;
  border-radius: 30px;
  background-image: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)),
    linear-gradient(349deg, #0044a9, #ff8f06, #ff899c);
}
</style>
<script>
// @ is an alias to /src«
import ASelect from "@/components/ASelect.vue";
import AComplete from "@/components/AComplete.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import LineChart from "@/components/charts/Line.vue";
import AppError from "@/utils/AppError.js";
export default {
  name: "ai-search",

  components: {
    ASelect,
    AComplete,
    LineChart,
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    formInputType: { required },
    formInputProduct: { required },
  },

  props: {
    product: {
      type: String,
      required: false,
      default: null,
    },
  },

  mounted: function () {
    this.getStore();
    this.getProducts();
    this.formInputProduct = this.product;
    console.log(this.product);
  },

  data() {
    return {
      formInputType: "SKU",
      formInputProduct: null,
      products: null,
      show: false,
      units: null,
      conversionRate: null,
      saleIncrease: null,
      error: false,
      errorMessage: null,
      lineChartData: null,
      cats: null,
      cannibalisationItems: null,
      solution: null,
      selectedTable: "table1",
      store: null,
    };
  },

  computed: {
    types() {
      return ["SKU"];
    },
  },

  methods: {
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get all the product sku's.
     * @public
     */
    getProducts() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.products;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.products = resp.data;
          console.log(this.products);
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    changeInputItem() {
      this.resetShow();
    },
    /**
     * Reset show.
     * @public
     */
    resetShow() {
      this.show = false;
    },
    /**
     * Get data.
     * @public
     */
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var sku = this.formInputProduct.split(":")[0];
      console.log(sku);

      let url = this.$config.api.route.aiSearch;
      url = url.replace(":id", this.store.id);
      url = url.replace(":sku", sku);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          console.log(resp.data);
          this.show = true;
          this.units = resp.data.units;
          this.conversionRate = resp.data.conversion_rate;
          this.lineChartData = [
            {
              name: this.$t("pastSales"),
              data: resp.data.chart_data.past_sales,
            },
            {
              name: this.$t("forecastSales"),
              data: resp.data.chart_data.forecast_sales,
            },
          ];
          this.cats = resp.data.chart_data.xAxis;
          this.wholeStoreTable = resp.data.segments.whole_store;
          this.sameCategoryTable = resp.data.segments.sames_category;
          this.solution = resp.data.solution;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });

      this.error = true;
      this.errorMessage = "Niche product detected";

      this.cannibalisationItems = [
        {
          sku: "23",
          description: "CARNE",
          cannibalisation: "23%",
        },
        {
          sku: "231232",
          description: "PET FOOD",
          cannibalisation: "5%",
        },
        {
          sku: "231",
          description: "FETTE",
          cannibalisation: "2%",
        },
        {
          sku: "231231",
          description: "GM SALMONE",
          cannibalisation: "12%",
        },
      ];
    },
    changeType() {},
  },
};
</script>

<style lang="scss" scoped>
.result-score {
  display: flex;
  align-items: center;
  border-radius: 2rem;
  margin: 3rem 0;
  justify-content: space-evenly;

  &__container {
    text-align: center;
    background: white;
    border-radius: 10px;
    min-width: 210px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;

    &--no-bg {
      background: none;
      padding: 0;
    }

    &--inverse {
      background: #00204dd6;

      .result-score__text--size {
        color: white;
      }
    }
  }

  &__text {
    color: #002147;
    line-height: 25px;

    &--size {
      font-size: 16px;
      font-weight: 100;
      margin: 0;
    }
  }
}
</style>
